/************ Custom Font ************/

@font-face {
  font-family: SNPro-Regular;
  src: url('./assets/fonts/SNPro-Regular.otf');
}

@font-face {
  font-family: SNPro-Black;
  src: url('./assets/fonts/SNPro-Black.otf');
}

/************ Default CSS ************/

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  scroll-padding-top: 125px;
  scroll-behavior: auto !important;
}

body {
  font-weight: 400;
  overflow-x: hidden;
  position: relative;
  color: #fff !important;
  background-color: #121212 !important;
}

h1 {
  padding: 15px;
  background: linear-gradient(90deg, rgba(170, 54, 124, 0.5), rgba(74, 47, 189, 0.5));
  border: 1px solid #F5A623;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  font-family: SNPro-Black;
  line-height: 1;
  font-weight: 900;
  text-transform: uppercase;
  letter-spacing: 1px;
}

p,
a,
li,
button,
ul,
th,
tr {
  margin: 0;
  padding: 0;
  font-family: SNPro-Regular;
  line-height: 1.5;
  font-size: 16px;
  text-decoration: none !important;
}

a:hover {
  text-decoration: none !important;
}

b {
  color: #B8B8B8;
}

p a,
li a,
li button,
td a {
  color: #F5A623 !important;
}

p a:hover,
li a:hover,
li button:hover,
td a:hover {
  color: gold !important;
}

li>* {
  vertical-align: top !important;
}

li button {
  text-align: left;
}

img {
  width: 100%;
  height: auto;
}

button {
  border: 0;
  background-color: transparent;
}

input:focus,
textarea:focus,
select:focus {
  outline: none;
}

@media (min-width:1700px) {
  main .container {
    max-width: 100%;
    padding: 0 150px;
  }
}

p.success {
  color: green;
}

p.danger {
  color: red;
}

.no-padding {
  padding: 0 !important;
}

.divider {
  height: 3px;
  background-color: #F5A623;
}

.spacer-half {
  height: 25px;
}

.spacer {
  height: 50px;
}

.spacer-big {
  height: 150px;
}

/************ Navbar CSS ************/

nav.navbar {
  position: fixed;
  overflow: hidden;
  overflow-x: auto;
  width: 100%;
  height: 74px;
  top: 0;
  z-index: 200;
  background-color: #121212;
}

nav.navbar .container {
  padding: 0;
}

nav.navbar a.navbar-brand {
  width: 120px !important;
  height: auto !important;
}

nav.navbar .navbar-nav .nav-link.navbar-link {
  color: #fff !important;
  white-space: nowrap;
  letter-spacing: 0.8px;
  padding: 0 10px;
  opacity: 0.75;
  transition: 0.3s ease-in-out;
}

nav.navbar .navbar-nav a.nav-link.navbar-link:hover {
  opacity: 1;
}

nav.navbar .navbar-nav a.nav-link.navbar-link.selected {
  color: #F5A623 !important;
  opacity: 1;
}

span.navbar-text {
  display: flex;
  align-items: center;
}

#wikinav .social-icon {
  width: auto !important;
  height: 30px;
  margin-left: 0;
}

#wikinav .social-icon a {
  background-color: #121212;
}

#wikinav .social-icon a,
#wikinav .social-icon a::before {
  width: 30px;
  height: 30px;
  margin-left: 0;
  border: none;
}

.social-icon {
  display: inline-block;
  margin-left: 14px;
}

.social-icon a {
  width: 42px;
  height: 42px;
  background: rgba(217, 217, 217, 0.1);
  display: inline-flex;
  border: 1px solid rgba(255, 255, 255, 0.5);
  border-radius: 10px;
  margin-right: 6px;
  align-items: center;
  justify-content: center;
  line-height: 1;
}

.social-icon a:last-child {
  margin-right: 0;
}

.social-icon a::before {
  content: "";
  width: 42px;
  height: 42px;
  position: absolute;
  background-color: #ffffff;
  border-radius: 10px;
  transition: 0.3s ease-in-out;
  transform: scale(0);
}

.social-icon a:hover::before {
  transform: scale(1);
}

.social-icon a img {
  width: 60%;
  height: 60%;
  z-index: 1;
  object-fit: contain;
  transition: 0.3s ease-in-out;
}

.navbar-text button:hover {
  color: #121212;
}

.navbar-text button:hover::before {
  content: "";
  width: 100%;
  height: 100%;
  position: absolute;
}

.nav-mobile {
  flex-wrap: wrap !important;
  font-size: 14px !important;
}

.nav-mobile .social-icon a,
.nav-mobile .social-icon a::before {
  width: 28px !important;
  height: 28px !important;
}


/************ Banner CSS ************/

.banner {
  padding: 150px 0 50px 0;
  background: linear-gradient(0deg, rgba(48, 16, 64, 0.5), rgba(48, 16, 64, 0.5)), url('./assets/img/Background.png');
  background-position: top center;
  background-repeat: no-repeat;
  background-size: cover;
}

.banner-text {
  padding: 30px 50px !important;
  background: linear-gradient(to right, rgba(0, 0, 0, 0.5) 50%, rgba(0, 0, 0, 0.0) 80%);
  border-radius: 50px 0 0 50px;
  border-left: 3px solid #F5A623;
}

.banner-text>h1 {
  margin-bottom: 20px;
  display: inline-block;
}

.banner-text>p {
  line-height: 1.5em;
}

a .action-button {
  color: #fff;
  font-weight: 700;
  font-size: 21px;
  margin-top: 60px;
  letter-spacing: 0.8px;
  display: flex;
  align-items: center;
}

a .action-button-min {
  margin-top: 30px !important;
}

a .action-button svg:last-child {
  font-size: 25px;
  margin-left: 10px;
  transition: 0.3s ease-in-out;
  line-height: 1;
}

a:hover .action-button svg:last-child {
  margin-left: 25px;
}

.banner img {
  animation: updown 3s linear infinite;
}

@keyframes updown {
  0% {
    transform: translateY(-20px);
  }

  50% {
    transform: translateY(20px);
  }

  100% {
    transform: translateY(-20px);
  }
}

/************ Footer CSS ************/

#footer p {
  color: #fff;
  font-weight: 700;
  font-size: 21px;
  text-align: center;
  margin: 25px;
}

/************ Features CSS ************/

#features,
.neb-blue {
  background-image: url('./assets/img/TileBlueNebula.png');
  background-repeat: repeat;
}

.neb-blue>.container {
  background-color: #121212;
  border-left: 3px solid #F5A623;
  border-right: 3px solid #F5A623;
  padding-left: 30px;
  padding-right: 30px;
}

#features .row {
  background-color: rgba(0, 0, 0, 0.5);
  border-left: 3px solid #F5A623;
  border-right: 3px solid #F5A623;
  border-radius: 50px;
  overflow: hidden;
}

#features .row>div {
  padding: 0;
}

#features p {
  max-width: 400px;
  margin: 30px;
}

#features h1 {
  margin: 30px;
}

.overlay-target {
  position: relative;
}

/************ Updates CSS ************/

.timeline-element {
  padding: 10px 0 10px 40px;
  position: relative;
  background-color: inherit;
  width: 100%;
  left: 0;
}

.timeline-element::after {
  content: "";
  position: absolute;
  top: 15px;
  left: 0;
  width: 20px;
  height: 20px;
  background-color: rgba(255, 255, 255, 0.1);
  border-radius: 50%;
  z-index: 1;
}

.timeline-element::before {
  content: " ";
  position: absolute;
  top: 15px;
  left: 30px;
  width: 0;
  height: 0;
  border: medium solid white;
  border-color: transparent rgba(255, 255, 255, 0.1) transparent transparent;
  border-width: 10px 10px 10px 0;
  z-index: 1;
}

.timeline-content {
  padding: 20px 30px;
  background-color: rgba(255, 255, 255, 0.1);
  position: relative;
  border-radius: 5px;
}

.timeline-content>h2 {
  padding: 0;
  font-size: 25px;
  text-transform: none;
}

.reddit {
  padding: 10px 0 !important;
}

.reddit .row {
  --bs-gutter-x: 10px;
}

.feed-tile {
  margin-bottom: 25px;
}

.feed {
  height: 100%;
  padding: 10px;
  background-color: rgba(255, 255, 255, 0.1);
  border-radius: 5px;
  border: 3px solid transparent;
}

.feed:hover {
  border: 3px solid #F5A623;
}

.feed>p {
  margin: 0;
}

.feed-thumbnail {
  margin-bottom: 10px;
}

.feed-link,
.feed-link:hover {
  color: white;
}

/************ Wiki CSS ************/

#wiki input {
  padding: 5px;
}

#wiki h3,
#wiki h5 {
  font-family: SNPro-Regular;
  text-transform: none;
  border-bottom: 1px solid rgba(255, 255, 255, 0.15);
}

#wikinav {
  position: fixed;
  width: 100%;
  height: auto;
  top: 74px;
  z-index: 100;
  padding: 5px 0 3px 0;
  background-color: #242424;
}

#wikinav>.container {
  padding: 0;
  background-color: transparent;
}

#wikinav>.container>div,
#wikinav>.container>div>div {
  width: 100%;
  display: inline-block;
  position: relative;
}

#wikinav .wrapper {
  border-radius: 10px;
}

table,
table tr {
  width: 100%;
}

.info-box table,
.info-box th,
.info-box td {
  border: 1px solid #F5A623 !important;
}

.info-box th:first-child,
.category-box th:first-child,
.image-cell {
  width: 1%;
}

.image-cell>img {
  min-width: 96px;
}

.image-cell {
  padding: 0;
}

table,
th,
td,
.info-image {
  border: 1px solid rgba(255, 255, 255, 0.15) !important;
}

th,
td,
.info-image {
  padding: 10px;
}

td {
  white-space: pre-line;
}

.info-image>p {
  margin: 0;
  margin-top: 10px;
  padding: 10px;
  background: #242424;
  white-space: pre-line;
}

.info-image figure {
  overflow: hidden;
}

.info-image img {
  transition: all 0.25s ease-in-out;
}

.info-image img:hover {
  opacity: 0.5;
}

.info-box th {
  background: linear-gradient(90deg, rgba(74, 47, 189, 0.5), rgba(170, 54, 124, 0.5));
}

th {
  background: #242424;
}

.info-box img,
.info-box svg {
  width: auto;
  max-width: 100%;
  max-height: 300px;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.category-box ul {
  margin: 0;
  padding: 0;
}

.category-box li {
  display: inline-block;
}

.category-box li:not(:last-child)::after {
  content: "\25CF";
  padding: 5px;
}

.icon-list {
  padding-left: 16px;
}

.icon-list>li {
  list-style: none;
}

.icon-list>li>svg {
  margin: 3px;
  margin-right: 10px;
}

.custom-box {
  height: 100%;
  border: 1px solid rgba(255, 255, 255, 0.15) !important;
}

.custom-box-header {
  padding: 10px;
  margin-bottom: 5px;
  background: #242424;
}

.custom-box-body {
  padding: 10px;
}

.table-of-contents {
  padding: 10px;
  border: 1px solid rgba(255, 255, 255, 0.15) !important;
}

.table-of-contents>ul {
  margin: 0;
  padding-left: 20px;
}

.no-list-bullets {
  list-style: none;
}

.gallery-image {
  border: 2px solid #F5A623;
  border-radius: 20px;
}

.column-list {
  columns: 200px;
}